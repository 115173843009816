.FlashSplash {
    position: relative;
    z-index: 1;
}

.FlashSplash-image::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.2));
}

.FlashSplash-first {
    position: relative;
    margin-top: -50px;
    z-index: 2;
}

.FlashSplash-header {
    position: absolute;
    top: 0;
    left: 0;
}

.FlashSplash-linear-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 6rem;
    width:100vw;
    background:linear-gradient(12deg, white, white 50%, transparent 50%, transparent);
    margin-bottom: -2px;
}
